<script src="../../router/index.js"></script>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Izvēles lauki"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <b-button variant="success" class="float-right" :to="{ name: 'OptionCreate' }">Pievienot</b-button>

          <b-col cols="12" class="text-center" v-if="!loaded">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </b-col>

          <b-modal id="modal-sm" size="sm" title="Dzēst lauku" ok-variant="danger" @ok="deleteItem" ok-title="Jā" cancel-title="Nē" bvModalEvent.trigger = 'delete()'>Vai jūs tiešām vēlaties dzēst šo lauku?</b-modal>

          <b-modal id="modal-position" size="sm" title="Dzēst lauku" ok-variant="danger" @ok="deletePosition" ok-title="Jā" cancel-title="Nē" bvModalEvent.trigger = 'delete()'>Vai jūs tiešām vēlaties dzēst šo lauku?</b-modal>

          <b-alert
              :show="dismissCountDown"
              v-if="deleted == 'success'"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Lauks tika veiksmīgi izdzēsts!</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-alert
              :show="deleted == 'error'"
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Ups! Notika kļūda</p>
            <div v-if="!position">
              <p>
                Šo izvēles lauku nevar izdzēst, jo viņš tiek pielietots sekojošos dokumentos:
              </p>
              <ul>
                <li v-for="d in errorMessage" :key="d.id">
                  <a :href="'/documents/view/'+d.id" target="_blank">{{ d.id }}</a>
                </li>
              </ul>
            </div>
            <div v-else-if="head">
              <p>
                Šo izvēles lauku nevar izdzēst, jo tam ir norādīts tiešais vadītājs:
              </p>
              <ul>
                <li v-for="d in errorMessage" :key="d.id">
                  <a :href="'/options/view/'+d.pivot.option_id" target="_blank">{{ d.name }} {{ d.surname }}</a>
                </li>
              </ul>
            </div>
            <div v-else-if="users">
              <p>
                Šo izvēles lauku nevar izdzēst, jo tam ir pievienoti šie lietotāji:
              </p>
              <ul>
                <li v-for="d in errorMessage" :key="d.id">
                  <a :href="'/users/edit/'+d.id" target="_blank">{{ d.name }} {{ d.surname }}</a>
                </li>
              </ul>
            </div>
            <div v-else>
              <p>
                Šo izvēles lauku nevar izdzēst, jo viņš tiek pielietots sekojošos lietotajos:
              </p>
              <ul>
                <li v-for="d in errorMessage" :key="d.id">
                  <a :href="'/users/edit/'+d.id" target="_blank">{{ d.id }}</a>
                </li>
              </ul>
            </div>
          </b-alert>

          <b-tabs content-class="mt-3" v-if="loaded">
            <b-tab :title="list.title" :active="$route.params.tab === list.title" v-for="list in options" v-if="list.title" :key="list.title">
              <b-table striped hover responsive="true" fixed :items="list.data" :fields="fields" v-if="loaded && list.title !== 'Amati'">

                <template #cell(actions)="row">
                  <b-button v-can="'options.update'" size="sm" variant="warning" @click="$router.push({ name: 'OptionView', params: {id: row.item.value, tab: list.title} })" class="mr-1">
                    Labot/Skatīt
                  </b-button>
                  <b-button v-can="'options.destroy'" size="sm" variant="danger" v-b-modal.modal-sm @click="selectedId = row.item.value">
                    Dzēst
                  </b-button>
                </template>
              </b-table>
              <b-table striped hover responsive="true" fixed :items="list.data" :fields="fields" v-else-if="loaded">

                <template #cell(actions)="row">
                  <div>{{ row }}</div>
                  <b-button v-can="'options.update'" size="sm" variant="warning" @click="$router.push({ name: 'PositionView', params: {id: row.item.value} })" class="mr-1">
                    Labot/Skatīt
                  </b-button>
                  <b-button v-can="'options.destroy'" size="sm" variant="danger" v-b-modal.modal-position @click="selectedId = row.item.value">
                    Dzēst
                  </b-button>
                </template>
              </b-table>
            </b-tab>
          </b-tabs>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "List",
  data() {
    return {
      loaded: false,
      position: false,
      fields : [
        { key: 'text', label: 'Nosaukums' },
        { key: 'actions', label: 'Darbības' }
      ],
      selectedId: null,
      deleted: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      errorMessage: null,
      head: false,
      users: false
    }
  },
  computed: {
    ...mapGetters ({
      options: "Options/options",
    })
  },
  methods: {
    ...mapActions({
      fetchOptions: "Options/fetchOptions"
    }),
    deleteItem() {
      this.position = false;
      if(this.selectedId != null) {
        axios.delete('/options/'+this.selectedId).then(response => {
          this.deleted = 'success'

          this.fetchOptions()
        }).catch(e => {
          if(e.response.data.message[0]?.surname) {
            this.position = true
            this.head = true
            this.deleted = 'error'
            if(!e.response.data.message[0].pivot?.option_id) {
              this.head = false
              this.users = true
            }
            this.errorMessage = e.response.data.message
          } else {
            this.deleted = 'error'
            this.errorMessage = e.response.data.message
            console.log(e.response.data.message)
          }
        })

        this.dismissCountDown = 5;
      }
    },
    deletePosition() {
      this.position = true;
      if(this.selectedId != null) {
        axios.delete('/positions/'+this.selectedId).then(response => {
          this.deleted = 'success'

          this.fetchOptions()
        }).catch(e => {
          this.deleted = 'error'
          this.errorMessage = e.response.data.message
          console.log(e.response.data.message)
        })

        this.dismissCountDown = 5;
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
  },
  async mounted() {
    await this.fetchOptions()
    this.loaded = true
  },
}
</script>

<style scoped>

</style>